<template>
  <div></div>
</template>

<script>
  export default {
    mounted () {
      if (this.$route.query && this.$route.query.rid) {
        this.referrer.id = this.$route.query.rid

        if (this.$route.query.rtok) {
          this.referrer.token = this.$route.query.rtok
        }

        this.$store.commit('cacheReferrer', this.referrer)
      }
    },
    data: () => ({
      referrer: {
        id: null,
        token: null,
      },
    }),
  }
</script>
